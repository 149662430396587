/* eslint-disable react/require-default-props */
import React, {
  createElement,
  forwardRef,
  AllHTMLAttributes,
  ForwardRefExoticComponent,
  RefAttributes,
} from "react"
import clsx from "clsx"

type ElementType =
  | HTMLDivElement
  | HTMLAnchorElement
  | HTMLFormElement
  | HTMLButtonElement
  | HTMLDListElement
  | HTMLDataElement
  | HTMLElement

export type CustomTagArgs<T = ElementType> = {
  as?: any | keyof React.JSX.IntrinsicElements
  inert?: string | null | boolean
  // TODO: Figure out how to cast the correct types based on defaultTag / as
} & AllHTMLAttributes<T>

/**
 * Creates a re-usable element with pre-set classnames
 * Great for building generic, pure, Tailwind components
 */
export default function createStyle<T = ElementType>(
  defaultTag: any | keyof React.JSX.IntrinsicElements,
  classes = "",
  defaultProps: { displayName?: string } & AllHTMLAttributes<T> = {}
) {
  const { displayName = null, ...tagProps } = defaultProps
  const customStyledTag: ForwardRefExoticComponent<
    CustomTagArgs<T> & RefAttributes<T>
  > & { classNames?: string } = forwardRef<T, CustomTagArgs<T>>(
    ({ as, className = "", children, ...props }, ref) =>
      createElement(
        as || defaultTag,
        {
          className: clsx(classes, className),
          ref,
          ...tagProps,
          ...props,
        },
        children
      )
  )

  customStyledTag.displayName = displayName || `*${defaultTag}`
  customStyledTag.classNames = classes

  return customStyledTag
}
