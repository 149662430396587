export default class ErrorWithData extends Error {
  public data: any

  public statusCode: number

  constructor(message: string, data?: any, statusCode?: number) {
    super(message)
    this.data = data
    this.statusCode = statusCode
  }
}
