import clsx from "clsx"

import createStyle from "lib/createStyle"

export const themes = {
  primary: clsx("bg-black text-white border border-black"),
  secondary: clsx("bg-transparent text-black border border-black"),
  tertiary: clsx("bg-transparent underline"),
  project: clsx("bg-transparent no-underline w-full"),
  white: clsx("bg-white hover:bg-rodeo text-black"),
  gold: clsx("bg-gold text-black"),
  rodeo: clsx("bg-rodeo text-offblack"),
  ghost: clsx("bg-transparent border border-current text-current"),
  destructive: clsx("bg-red text-white"),
  pea: clsx("bg-pea text-white"),
  noSubmit: clsx("bg-pea text-[#fffff430] cursor-not-allowed"),
  back: clsx(
    "!absolute bottom-[58px] p-6 h-[76px] max-w-[168px] !font-[600] leading-[20px] mb-[48px] disabled:text-white w-full uppercase disabled:text-opacity-20 bg-white disabled:bg-transparent text-offblack transition duration-300"
  ),
  custom: "",
}

export const sizes = {
  large:
    "text-[0.8125rem] lg:text-[0.875rem] leading-[1.15] tracking-[0.01em] py-4 px-5 min-w-[140px] uppercase font-semibold",
  action: "text-[0.875rem] leading-[1.15] tracking-[0.01em] py-2 px-3",
  custom: "",
}

export const ButtonBase = createStyle(
  "a",
  "inline-flex items-center transition duration-150 relative group text-center disabled:text-medgray disabled:bg-black disabled:border-black"
)
