import { HTMLAttributes, isValidElement } from "react"
import { ErrorBoundary } from "@sentry/nextjs"
import clsx from "clsx"

import { FormError } from "./Forms/types"

// For forms with nested objects, Django returns nested error messages, this should unpack them
const ErrorPathUnwrapper = ({ path, message }) => {
  const fieldRef: HTMLInputElement =
    typeof window !== "undefined" && document.querySelector(`[name="${path}"]`)
  if (path === "non_field_errors") {
    return <li>{message.join("\n")}</li>
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {((typeof message === "string" || isValidElement(message)) && (
        <li>{message}</li>
      )) ||
        (message instanceof Array && (
          <li>
            Error {fieldRef ? "with field" : "on field"}{" "}
            {fieldRef ? (
              <button
                type="button"
                className="font-semibold underline"
                onClick={() => {
                  fieldRef.focus()
                  fieldRef.scrollIntoView({ block: "center" })
                }}
              >
                &ldquo;{path}&rdquo;
              </button>
            ) : (
              path
            )}
            : {message.join("\n")}
          </li>
        )) ||
        (typeof message === "object" &&
          Object.entries(message).map(([k, v]) => (
            <ErrorPathUnwrapper
              key={["error", "children", path, k].join("_")}
              path={`${path}.${k}`}
              message={v}
            />
          ))) || <li>{JSON.stringify(message, null, 2)}</li>}
    </>
  )
}

const FILTERED_KEYS = ["response", "url"]

export const ErrorUnwrapper = ({
  error,
  className = "",
  ...props
}: { error: FormError | null } & HTMLAttributes<
  HTMLDivElement | HTMLUListElement
>) => {
  if (!error) {
    return null
  }

  if (error && "detail" in error) {
    return (
      <span className={clsx(className)} {...props}>
        {error.detail}
      </span>
    )
  }

  if (
    error &&
    Object.keys(error).filter((k) => !FILTERED_KEYS.includes(k)).length > 0
  ) {
    const visibleErrors = Object.entries(error)
      .filter(([k]) => !FILTERED_KEYS.includes(k))
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
    return (
      <ul
        className={clsx(
          Object.keys(visibleErrors).length > 1 && "list-disc pl-3",
          className
        )}
        {...props}
      >
        <ErrorBoundary fallback={<li>Unhandled Error</li>}>
          {Object.entries(visibleErrors ?? {}).map(([path, message]) => (
            <ErrorPathUnwrapper key={path} path={path} message={message} />
          ))}
        </ErrorBoundary>
      </ul>
    )
  }
  if ("response" in error) {
    return (
      <ul className={clsx("list-disc pl-3", className)} {...props}>
        <li>Unhandled error. Don&apos;t worry, our team has been notified!</li>
        <li>Response code: {error.response.status}</li>
        <li>Response type: {error.response.statusText}</li>
      </ul>
    )
  }

  if (error) {
    return (
      <div {...props} className={clsx(className, "whitespace-pre-wrap")}>
        {JSON.stringify(error, null, 2)}
      </div>
    )
  }

  return null
}

export default ErrorUnwrapper
