import createStyle from "lib/createStyle"

export const Serif2XL = createStyle(
  "h1",
  "font-serif font-thin text-[6rem] lg:text-[10.625rem] leading-[1.1] tracking-[-0.03em]" // 170px
)

export const SerifH1 = createStyle(
  // "XL"
  "h1",
  "font-serif font-thin text-[5rem] lg:text-[6.25rem] leading-[1] tracking-[-0.02em]" // 100px
)

export const SerifH2 = createStyle(
  // "L"
  "h2",
  "font-serif font-thin text-[3.125rem] leading-[1.2] tracking-[-0.01em]" // 50px
)
export const Heading = createStyle(
  // "L"
  "h2",
  "font-serif font-thin text-[46px] leading-[56px] tracking-[-0.5px]" // 46px
)

export const SerifH3 = createStyle(
  // "M"
  "h3",
  "font-serif  text-[2.5rem] leading-[1.2] tracking-[-0.01em]" // 40px
)

export const SerifH4 = createStyle(
  // "S"
  "h4",
  "font-serif font-light text-[1.75rem] leading-[1.3] tracking-[-0.01em]" // 28px
)
export const SerifH5 = createStyle(
  // "XS"
  "h5",
  "font-serif font-light text-[1.25rem] lg:text-[1.125rem] leading-[1.4]" // 18px
)

export const SerifH6 = createStyle(
  // "2XS"
  "h6",
  "font-serif font-light text-[1rem] lg:text-[0.75rem] leading-[1.7]" // 12px
)
export const SerifH7 = createStyle(
  // "2XS"
  "h6",
  "font-serif font-light text-[0.875rem] lg:text-[0.875rem] leading-[1.7]" // 14px
)
export const SerifH8 = createStyle(
  // "2XS"
  "h6",
  "font-serif font-light text-[0.6rem] lg:text-[0.6rem] leading-[1.7]" // 14px
)
export const XXL = createStyle(
  "h1",
  "font-medium text-[2.875rem] leading-[1] tracking-[-0.02em]"
)

export const H1 = createStyle(
  // "XL"
  "h1",
  "font-medium text-[1.875rem] leading-[1] md:text-[1.875rem] tracking-[-0.02em] " // 30px, 40px on medium screens and above
)

export const H2 = createStyle(
  // "L"
  "h2",
  "font-medium text-[1.25rem] leading-[1.2] tracking-[0.01em]" // 20px
)

export const H3 = createStyle(
  // "M med"
  "h3",
  "font-medium text-[1.25rem] lg:text-[1rem] leading-[1.5] tracking-[-0.01em]" // 16px
)

export const H4 = createStyle(
  // "S med"
  "h4",
  "font-medium text-[0.875rem]] lg:text-[0.875rem] leading-[1.4] tracking-[-0.01em]" // 14px
)

export const H5 = createStyle(
  // "XS bold"
  "h5",
  "text-[0.875rem] lg:text-[0.75rem] font-semibold leading-[1.5] tracking-[0.05em]" // 12px
)

export const H6 = createStyle(
  // "XXS bold"
  "h6",
  "text-[0.75rem] lg:text-[0.5625rem] leading-[2] font-semibold tracking-[0.04em] uppercase" // 9px
)

export const H7 = createStyle(
  "h6",
  "leading-both text-cap font-editorial-new text-20 font-normal font-light" // 12px
)

export const Lead = createStyle(
  "p",
  "text-[1rem] lg:text-[1.25rem] leading-[1.3]"
)

export const P = createStyle(
  "p",
  "text-[1.25rem] lg:text-[1rem]  tracking-[-0.01em] leading-[1.5]" // 16px
)

export const Small = createStyle(
  "p",
  "text-[1rem] lg:text-[0.875rem] tracking-[0.01em] leading-[1.4]" // 14px
)

export const XSMed = createStyle(
  "p",
  "text-[0.875rem] lg:text-[0.75rem] font-medium tracking-[0.04em] uppercase leading-[1.5]" // 12px
)

export const XSmall = createStyle(
  // "XS reg"
  "p",
  "text-[0.875rem] lg:text-[0.75rem] leading-[1.5]" // 12px
)

export const XXSmall = createStyle(
  // "XXS reg"
  "p",
  "text-[0.75rem] lg:text-[0.625rem] leading-[2] tracking-[0.04em] uppercase" // 9px
)
export const XSSmall = createStyle(
  "p",
  "text-[10px] leading-[18px] tracking-[0.4px] text-charcoal uppercase" // 10px
)

export const Retron = createStyle(
  "span",
  "font-retron text-[0.88em] align-[0.015em]"
)
export const TypeStyles = {
  Serif2XL,
  SerifH1,
  SerifH2,
  Heading,
  SerifH3,
  SerifH4,
  SerifH5,
  SerifH6,
  SerifH7,
  XXL,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  H7,
  P,
  Small,
  XSMed,
  XSmall,
  XXSmall,
  XSSmall,
}
