import { forwardRef } from "react"
import clsx from "clsx"
import Link from "next/link"

import TooltipWrapper from "components/Tooltip"
import InfoIcon from "icons/Info"
import { ButtonBase, themes, sizes } from "./styles"
import { ButtonProps } from "./types"

const ButtonEl = forwardRef<HTMLElement, ButtonProps>(
  (
    {
      className = "",
      label,
      theme = "primary",
      size = "large",
      tooltip,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <ButtonBase
        {...props}
        className={clsx(className, themes[theme], sizes[size])}
        ref={ref}
      >
        <div
          className={clsx(
            "flex w-full",
            "transition-opacity duration-300",
            "items-center",
            "justify-center",
            "flex-grow",
            tooltip && "px-5"
          )}
        >
          {label || children}
        </div>
        {tooltip && (
          <TooltipWrapper {...tooltip}>
            <InfoIcon className="-ml-5 w-5 h-5 text-white" />
          </TooltipWrapper>
        )}
      </ButtonBase>
    )
  }
)

export const Button = forwardRef<HTMLElement, ButtonProps>(
  ({ href, nativeLink = false, className = "", onClick, ...props }, ref) => {
    if (
      !nativeLink &&
      (href?.slice(0, 1) === "/" || href?.slice(0, 1) === "#")
    ) {
      return (
        <Link href={href} passHref legacyBehavior>
          <ButtonEl ref={ref} {...props} />
        </Link>
      )
    }
    const isHash = href?.slice(0, 1) === "#"
    return (
      <ButtonEl
        ref={ref}
        href={href}
        target={href && !isHash && !nativeLink ? "_blank" : undefined}
        rel={href && !isHash && !nativeLink ? "noopener" : undefined}
        className={`${onClick ? "cursor-pointer" : ""} ${className}`}
        onClick={onClick}
        {...props}
      />
    )
  }
)

export default Button
