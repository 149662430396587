"use client"

import { FC, useState } from "react"
import NextImage, { ImageProps } from "next/image"
import clsx from "clsx"

export const Image: FC<
  ImageProps & { customLoader?: boolean; autoHeight?: boolean }
> = ({
  className = "",
  customLoader = false,
  autoHeight = false,
  ...props
}) => {
  const [isLoaded, setIsLoaded] = useState(!!customLoader)

  if (!props.src) {
    return null
  }

  return (
    <NextImage
      className={clsx(
        className,
        !isLoaded && "opacity-0",
        !(className || "")?.includes("object-contain") && "object-cover",
        "transition duration-500"
      )}
      onLoad={() => setIsLoaded(true)}
      style={
        props.width &&
        !autoHeight &&
        props.height &&
        !/(w-\d+)|(h-\d+)/.test(className)
          ? {
              width: Number(props.width),
              height: Number(props.height),
            }
          : undefined
      }
      {...props}
    />
  )
}
export default Image
